












































































































































































































































import OrgBanner from '../components/OrgBanner.vue'
import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { getIconWhite, getIconBlue } from '../model/EventIcons'
import { mapState, mapActions } from 'vuex'
import { slider, slideritem } from 'vue-concise-slider'
import firebase from "firebase/app";
import "firebase/functions"

@Component({
  components: {
    slider,
    slideritem,
    OrgBanner
  },
  computed: {
    ...mapState([
      'activeEvent',
      'eventReactions'
    ])
  },
  methods: {
    ...mapActions(['loadEventDetails']),
    GetPackageTypes(beer) {
      if (beer.packageTypes != null && beer.packageTypes.length > 0) {
        return beer.packageTypes
      } else if (beer?.packageType !== '') {
        return [beer.packageType]
      } else {
        return []
      }
    }
  }
})


export default class Event extends Vue {

  private activeEvent

  private loading = true
  private reactionLoading = false
  private eventReactions!: Map<string, boolean>

  
  private mounted() {
    if (this.activeEvent != null) {
      const title = this.activeEvent.name + ' by ' + this.activeEvent.organization.name
      document.title = title
      const tag = document.createElement('meta');
      tag.setAttribute('name', 'description')
      tag.setAttribute('content', this.activeEvent.description)
      // tag as router controlled so that router removes when route changes
      tag.setAttribute('data-vue-router-controlled', '');
    
    
      document.querySelector('[property="og:title"]')?.setAttribute('content', title);
      document.querySelector('[name="twitter:title"]')?.setAttribute('content', title);

      const desc = this.activeEvent.description
      document.querySelector('[name="description"]')?.setAttribute('content', desc);
      document.querySelector('[property="og:description"]')?.setAttribute('content', desc);
      document.querySelector('[name="twitter:description"]')?.setAttribute('content', desc);

      const imgUrl = this.activeEvent.imageUrls[0]
      document.querySelector('[property="og:image"]')?.setAttribute('content', imgUrl);
      document.querySelector('[name="twitter:image"]')?.setAttribute('content', imgUrl);
    }
  }

  private async created() {
    const id = this.$route.params.id
    await this.$store.dispatch('loadEventDetails', id)
    this.loading = false

    if (this.activeEvent != null) {
      const title = this.activeEvent.name + ' by ' + this.activeEvent.organization.name
      document.title = title
      const tag = document.createElement('meta');
      tag.setAttribute('name', 'description')
      tag.setAttribute('content', this.activeEvent.description)
      // tag as router controlled so that router removes when route changes
      tag.setAttribute('data-vue-router-controlled', '');
    
    
      document.querySelector('[property="og:title"]')?.setAttribute('content', title);
      document.querySelector('[name="twitter:title"]')?.setAttribute('content', title);

      const desc = this.activeEvent.description
      document.querySelector('[name="description"]')?.setAttribute('content', desc);
      document.querySelector('[property="og:description"]')?.setAttribute('content', desc);
      document.querySelector('[name="twitter:description"]')?.setAttribute('content', desc);

      const imgUrl = this.activeEvent.imageUrls[0]
      document.querySelector('[property="og:image"]')?.setAttribute('content', imgUrl);
      document.querySelector('[name="twitter:image"]')?.setAttribute('content', imgUrl);
    }

    // TODO: 404 for Event not found
  }

  private get blueIcon() {
    return getIconBlue('BREWERY_RELEASE')
  }

  private get whiteIcon() {
    return getIconWhite(this.activeEvent.type)
  }

  private get eventDescription() {
    let desc
    switch (this.activeEvent.type) {
      case 'BREWERY_RELEASE':
        desc = 'Brewery Release'
        break;
      case 'MOBILE_RELEASE':
        desc = 'Mobile Release'
        break;
      case 'ONLINE_RELEASE':
        desc = 'Online Release'
        break;
      case 'FESTIVAL':
        desc = 'Festival'
        break;
      case 'TAP_TAKEOVER':
        desc = 'Tap Takeover'
        break;
      case 'NEWS':
        desc = 'News'
        break;
      case 'MERCH':
        desc = 'Merch Drop'
        break;
      default:
        desc = 'Event'
    }
    return desc
  }

  private get eventLocations(): Array<Location> {
    if (this.activeEvent.locations != null && this.activeEvent.locations.length > 0) {
      return this.activeEvent.locations
    } else if (this.activeEvent.location !== undefined && this.activeEvent.location !== undefined) {
      return [this.activeEvent.location]
    } else {
      return []
    }
  }

  private get eventObjReactions(): Array<any> {
    var newArray = Array<any>()

    if (this.activeEvent.reactionGroups != null) {
      for (const [key, value] of Object.entries(this.activeEvent.reactionGroups["0"].reactions)) {
        newArray.push(value)
      }

      return newArray.sort(({ order: a }, { order: b }) => a - b);
    }

    return newArray
  }

  private didReact(reaction: any): boolean {
    const eventGroupId = this.activeEvent.id + "-" + "0" // Eventually dont hardcode the groupId 

    if (this.eventReactions[eventGroupId] != null) {
      const reactionArr = this.eventReactions[eventGroupId]

      if (reactionArr.length > 0) {
        return reactionArr.includes(reaction.reactionId)
      }

      return false
    }

    return false
  }

  sliderOptions = {
    currentPage: 0,
    itemAnimation: true,
    slidesToScroll: 1,
    loopedSlides: 2,
    loop: true,
    autoplay: 5000
  }

  private get brandColor() {
    if (this.activeEvent.organization.style?.eventCardColor != null) {
      return this.activeEvent.organization.style.eventCardColor
    } else {
      return 'primary'
    }
  }

  private get brandTextColor() {
    if (this.activeEvent.organization.style?.eventTextColor != null) {
      return this.activeEvent.organization.style.eventTextColor
    } else {
      return '#ffffff'
    }
  }

  private async reactionSelected(reaction: any): Promise<boolean> {
    this.reactionLoading = true

    // Super dumb any this took me forever to figre out but we need to for some reason
    // start up the messaging SDK before calling a cloud func if its used 🤷‍♂️
    if (!('ANDROID_WEB_VIEW' in window) && firebase.messaging.isSupported()) {
      try {
        const vapidKey = 'BCDD-yDrb1pyCuQZOWZwvOQVO9zCjtOeYMHJDFsU1JM73w1X9XGf4n4iVPAj_LNlVAdpq_wWzw51DepU1D_uk88'
        await firebase.messaging().getToken({ vapidKey })
      } catch (e) {
        return false
      }
    }

    const didLike = !this.didReact(reaction)
    const body = {
      eventId: this.activeEvent.id,
      groupId: "0", // Eventually this will by dynamic when we support multiple reactions
      reactionId: reaction.reactionId,
      didLike: didLike
    }

    const result = await firebase.functions().httpsCallable('updateEventReaction');
    result(body)
      .then((result) => {
        if (didLike) {
          this.$store.commit('eventReaction', this.activeEvent.id + "-" + "0" + "-" + reaction.reactionId) // TODO: Don't hardcode groups eventually
        }
        else {
          this.$store.commit('eventRemoveReaction', this.activeEvent.id + "-" + "0" + "-" + reaction.reactionId)
        }

        this.reactionLoading = false

        return result.data
      });

    return false
  }
}
