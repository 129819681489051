












































































































import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { collections } from '../firebase'

@Component
export default class IWantIn extends Vue {

  submitting = false

  done = false

  valid = false

  info = {
    orgName: '',
    email: '',
    type: 'Brewery'
  }

  orgTypes = [
    'Brewery',
    'Bottle Shop',
    'Bar/Restaurant',
    'Other'
  ]

  private async submit () {
    if (this.valid) {
      this.submitting = true
      await collections.iwantin.add({
        ...this.info,
        date: new Date()
      })
      this.submitting = false
      this.done = true
      setTimeout(() => { this.reset() }, 5000)
    }
  }

  private reset () {
    this.info.orgName = ''
    this.info.email = ''
    this.info.type = 'Brewery'
    this.done = false
  }

  private get rules () {
    return {
      name: [
        function notEmpty (val) {
          if (val == null || val.length === 0) {
            return 'Name cannot be empty.'
          }
          return true
        }
      ],
      email: [
        function notEmpty (val) {
          if (val == null || val.length === 0) {
            return 'E-mail cannot be empty.'
          }
          return true
        }
      ],
    }
  }

}
