export type IconType =
  'BREWERY_RELEASE' |
  'FESTIVAL' |
  'MOBILE_RELEASE' |
  'NEWS' |
  'ONLINE_RELEASE' |
  'TAP_TAKEOVER' |
  'EVENT' |
  'MERCH'

/**
 * White variants
 */
const ICON_BREWERY_RELEASE_WHITE = 'event_icon_breweryrelease_white.png'
const ICON_FESTIVAL_WHITE = 'event_icon_festival_white.png'
const ICON_MOBILE_RELEASE_WHITE = 'event_icon_mobile_white.png'
const ICON_NEWS_WHITE = 'Event_Icon_News_White.png'
const ICON_ONLINE_RELEASE_WHITE = 'event_icon_online_white.png'
const ICON_TAP_TAKEOVER_WHITE = 'event_icon_taptakeover_white.png'
const ICON_GENERAL_EVENT_WHITE = 'General_Event_Icon-White.png'
const ICON_MERCH_EVENT_WHITE = 'Event_Icon_Merch_White.png'

const WhiteVariants: Map<IconType, string> = new Map()
WhiteVariants.set('BREWERY_RELEASE', ICON_BREWERY_RELEASE_WHITE)
WhiteVariants.set('FESTIVAL', ICON_FESTIVAL_WHITE)
WhiteVariants.set('MERCH', ICON_MERCH_EVENT_WHITE)
WhiteVariants.set('MOBILE_RELEASE', ICON_MOBILE_RELEASE_WHITE)
WhiteVariants.set('NEWS', ICON_NEWS_WHITE)
WhiteVariants.set('ONLINE_RELEASE', ICON_ONLINE_RELEASE_WHITE)
WhiteVariants.set('TAP_TAKEOVER', ICON_TAP_TAKEOVER_WHITE)
WhiteVariants.set('EVENT', ICON_GENERAL_EVENT_WHITE)

/**
 * Blue variants
 */
const ICON_BREWERY_RELEASE_BLUE = 'Event_Icon_BreweryRelease_Blue.png'
const ICON_FESTIVAL_BLUE = 'Event_Icon_Festival_Blue.png'
const ICON_MOBILE_RELEASE_BLUE = 'Event_Icon_Mobile_Blue.png'
const ICON_NEWS_BLUE = 'Event_Icon_News_Blue.png'
const ICON_ONLINE_RELEASE_BLUE = 'Event_Icon_Online_Blue.png'
const ICON_TAP_TAKEOVER_BLUE = 'Event_Icon_TapTakeover_Blue.png'
const ICON_GENERAL_EVENT_BLUE = 'General_Event_Icon-2.png'
const ICON_MERCH_EVENT_BLUE = 'Event_Icon_Merch_Blue.png'

const BlueVariants: Map<IconType, string> = new Map()
BlueVariants.set('BREWERY_RELEASE', ICON_BREWERY_RELEASE_BLUE)
BlueVariants.set('FESTIVAL', ICON_FESTIVAL_BLUE)
BlueVariants.set('MERCH', ICON_MERCH_EVENT_BLUE)
BlueVariants.set('MOBILE_RELEASE', ICON_MOBILE_RELEASE_BLUE)
BlueVariants.set('NEWS', ICON_NEWS_BLUE)
BlueVariants.set('ONLINE_RELEASE', ICON_ONLINE_RELEASE_BLUE)
BlueVariants.set('TAP_TAKEOVER', ICON_TAP_TAKEOVER_BLUE)
BlueVariants.set('EVENT', ICON_GENERAL_EVENT_BLUE)

/**
 * Map variants
 */
const ICON_BREWERY_RELEASE_MAP = 'map-icon-brewery.png'
const ICON_FESTIVAL_MAP = 'map-icon-festival.png'
const ICON_MOBILE_RELEASE_MAP = 'map-icon-mobile.png'
const ICON_NEWS_MAP = 'map-icon-news.png'
const ICON_ONLINE_RELEASE_MAP = 'map-icon-online.png'
const ICON_TAP_TAKEOVER_MAP = 'map-icon-taptakeover.png'
const ICON_MERCH = 'map-icon-merch.png'

const MapVariants: Map<IconType, string> = new Map()
MapVariants.set('BREWERY_RELEASE', ICON_BREWERY_RELEASE_MAP)
MapVariants.set('FESTIVAL', ICON_FESTIVAL_MAP)
MapVariants.set('MOBILE_RELEASE', ICON_MOBILE_RELEASE_MAP)
MapVariants.set('NEWS', ICON_NEWS_MAP)
MapVariants.set('ONLINE_RELEASE', ICON_ONLINE_RELEASE_MAP)
MapVariants.set('TAP_TAKEOVER', ICON_TAP_TAKEOVER_MAP)
MapVariants.set('MERCH', ICON_MERCH)

export function getIconWhite(type: IconType): string {
  let icon = WhiteVariants.get(type)
  if (icon == null) {
    icon = ICON_BREWERY_RELEASE_WHITE
  }
  return require(`../assets/${icon}`)
}

export function getIconBlue(type: IconType): string {
  let icon = BlueVariants.get(type)
  if (icon == null) {
    icon = ICON_BREWERY_RELEASE_BLUE
  }
  return require(`../assets/${icon}`)
}

export function getMapIcon(type: IconType): string {
  let icon = MapVariants.get(type)
  if (icon == null) {
    icon = ICON_BREWERY_RELEASE_MAP
  }
  return require(`../assets/${icon}`)
}
